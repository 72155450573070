import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BCity } from ':types';
import { API } from ':api';

export const useCities = () => {
  return useQuery<BCity[]>({
    queryKey: ['cities'],
    queryFn: () => {
      return API.get(Endpoints.cities());
    },
    initialData: [],
  });
};
