import { FC } from 'react';
import clsx from 'clsx';
import { Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';

interface Props {
  /** Подзаголовок */
  caption: string;
  /** Флаг отключения анимаций */
  disableAnimations?: boolean;
  /** Флаг отображения внутри слайдера */
  embedded?: boolean;
  /** Заголовок */
  name: string;
  /** Флаг выбора */
  selected?: boolean;
  /** Адрес изображения */
  src: string;
}

/**
 * @description Компонент отображения содержимого слайда
 */
export const SlideContent: FC<Props> = ({ caption, name, src, selected, disableAnimations, embedded }) => {
  return (
    <div className={clsx(styles.slide)}>
      <div
        className={clsx(
          styles.imageWrapper,
          disableAnimations && styles.disableAnimations,
          embedded && styles.embedded,
          selected && styles.selected,
        )}
      >
        <img alt={name} className={styles.image} src={src} />
      </div>

      <div
        className={clsx(
          styles.caption,
          disableAnimations && styles.disableAnimations,
          embedded && styles.embedded,
          selected && styles.selected,
        )}
      >
        <Typography variant="h4">{name}</Typography>
        <Typography variant="body3">{caption}</Typography>
      </div>
    </div>
  );
};
