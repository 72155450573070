import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@beeline/design-system-react';

import { works } from ':mocks/works';

import styles from './styles.module.scss';
import { WorkCard } from './workCard';
import { useVariant } from './hooks';

/**
 * @description Блок "Работа в билайн - это"
 */
export const WorkIsBanner = () => {
  const navigate = useNavigate();
  const { buttonSize, buttonVariant, titleVariant } = useVariant();

  const handleTeamClick = () => {
    navigate('/vacancies');
  };

  return (
    <div className={styles.workIsBanner}>
      <Typography className={styles.title} variant={titleVariant}>
        работа в билайне &mdash; это
      </Typography>
      <div className={styles.content}>
        {works.map((work) => (
          <WorkCard
            key={work.title}
            color={work.color}
            icon={work.icon}
            subtitleList={work.subtitleList}
            title={work.title}
          />
        ))}
      </div>
      <Button className={styles.button} onClick={handleTeamClick} size={buttonSize} variant={buttonVariant}>
        Стать частью команды
      </Button>
    </div>
  );
};
