import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BDirection } from ':types';
import { API } from ':api';

export const useDirection = () => {
  return useQuery<BDirection[]>({
    queryKey: ['directions'],
    queryFn: () => {
      return API.get(Endpoints.directions());
    },
    initialData: [],
  });
};
