import { FC } from 'react';
import clsx from 'clsx';

import { Slide } from '..';

import styles from './styles.module.scss';

interface Props {
  /** Флаг отключения анимаций */
  disableAnimations: boolean;
  /** Событие нажатия на слайд */
  onClick: (key: number) => () => void;
  /** Индекс выбранного слайда */
  selectedIndex: number;
  /** Массив слайдов */
  slides: Slide[];
}

/**
 * @description Отображение оригиналов и копий слайдов
 */
export const Slides: FC<Props> = ({ slides, onClick: handleClick, selectedIndex, disableAnimations }) => {
  const { length } = slides;

  const renderSlides = ({ offset = 0 }: { offset?: number } = {}) => {
    return slides.map(({ render }, index) => {
      const key = index + offset;
      const isSelected = selectedIndex === key;
      const isBefore = key < selectedIndex;
      const isAfter = key > selectedIndex;

      return (
        <div
          key={key}
          className={clsx(
            styles.slide,
            isBefore && styles.before,
            isAfter && styles.after,
            disableAnimations && styles.disableAnimations,
          )}
          onClick={handleClick(key)}
          role="button"
          tabIndex={isSelected ? 0 : -1}
        >
          <div className={styles.content}>{render({ isSelected, disableAnimations })}</div>
        </div>
      );
    });
  };

  return [...renderSlides(), ...renderSlides({ offset: length }), renderSlides({ offset: length * 2 })];
};
