import { FC } from 'react';
import cn from 'clsx';
import { Outlet, ScrollRestoration } from 'react-router-dom';

import styles from './styles.module.scss';

import { Footer, Header } from ':components';

/**
 *
 * @description Основной лейаут для сайта
 */
export const MainLayout: FC = () => {
  return (
    <div>
      <Header />
      <div className={cn('container', styles.layout)}>
        <ScrollRestoration />
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};
