import { Button, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import { vacancies } from ':mocks/vacancies';
import { useMobileView } from ':hooks/useMobileView';

import { VacancyCard } from './vacancyCard';
import styles from './styles.module.scss';
import { useVariant } from './hooks';

/**
 * @description Блок с вакансиями "Найди себя в билайне"
 */
export const FindYourselfBlock = () => {
  const navigate = useNavigate();
  const { isLargeViewUp } = useMobileView();
  const vacs = vacancies(isLargeViewUp);

  const { buttonVariant, endButton, lastRowImage, startButton, titleVariant } = useVariant(vacs.length);

  const handleShowVacancies = () => {
    navigate('/vacancies');
  };

  return (
    <div className={styles.findYourselfBlock}>
      <div className={styles.titleRow}>
        <Typography className={styles.title} variant={titleVariant}>
          найди себя в билайне
        </Typography>
        {startButton && (
          <Button onClick={handleShowVacancies} size={buttonVariant} variant="outlined">
            Смотреть все направления
          </Button>
        )}
      </div>
      <div className={styles.vacancies}>
        {vacs.map((vacancy, index) => (
          <VacancyCard
            key={vacancy.title}
            color={vacancy.color}
            image={index < lastRowImage && vacancy.image}
            subtitleList={vacancy.subtitleList}
            title={vacancy.title}
          />
        ))}
      </div>
      {endButton && (
        <Button onClick={handleShowVacancies} size={buttonVariant} variant="outlined">
          Смотреть все направления
        </Button>
      )}
    </div>
  );
};
