import { FC } from 'react';
import { Checkbox, Divider, ProgressButton, Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';

import { useMobileView } from ':hooks';

/**
 * @description Компонент отображение футера отклика вакансии
 */

type Props = {
  /** Форма в статусе отправки или нет */
  isSubmitting?: boolean;
};

export const VacancyFormFooter: FC<Props> = ({ isSubmitting = false }) => {
  const { isNormalViewUp } = useMobileView();

  const checkbox = (
    <div className={styles.checkbox}>
      <Checkbox />
      <Typography className={styles.text} variant="body2">
        <span>
          Я{' '}
          <a className={styles.link} href="">
            согласен на обработку персональных данных
          </a>
        </span>{' '}
        <span>
          и включение в{' '}
          <a className={styles.link} href="">
            кадровый резерв
          </a>
        </span>
      </Typography>
    </div>
  );

  if (isNormalViewUp) {
    return (
      <div className={styles.applicationFooter}>
        <Divider />

        <div className={styles.controls}>
          {checkbox}
          <ProgressButton size="large" state={isSubmitting ? 'loading' : 'default'} type="submit" variant="contained">
            Отправить отклик
          </ProgressButton>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.checkboxWrapper}>{checkbox}</div>
      <div className={styles.stickyButton}>
        <ProgressButton
          className={styles.button}
          fullWidth
          size="large"
          state={isSubmitting ? 'loading' : 'default'}
          type="submit"
          variant="contained"
        >
          Отправить отклик
        </ProgressButton>
      </div>
    </>
  );
};
