import photo1 from ':assets/img/why_beeline_1.png';
import photo2 from ':assets/img/why_beeline_2.png';
import photo3 from ':assets/img/why_beeline_3.png';
import photo4 from ':assets/img/why_beeline_4.png';
import photo5 from ':assets/img/why_beeline_5.png';

export const sliderItems = [
  {
    image: photo1,
    name: 'Мария',
    caption: 'Ведущий специалист по\xa0адаптации, билайн университет',
  },
  {
    image: photo2,
    name: 'Андрей',
    caption: 'Ведущий специалист корпоративных продаж',
  },
  {
    image: photo3,
    name: 'Юрий',
    caption: 'Старший бизнес-аналитик',
  },
  {
    image: photo4,
    name: 'Алёна',
    caption: 'Креативный директор',
  },
  {
    image: photo5,
    name: 'Александр',
    caption: 'Руководитель департамента планирования и\xa0оптимизации сети радиодоступа',
  },
];
