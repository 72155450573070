import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks/useMobileView';

interface VariantType {
  /** Вариант текста хэдера */
  headerVariant: TypographyVariant;
  /** Вариант title */
  textVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isLargeViewUp } = useMobileView();

  if (isLargeViewUp) {
    return {
      headerVariant: 'subtitle2',
      textVariant: 'body2',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      headerVariant: 'subtitle2',
      textVariant: 'body3',
    };
  }

  return {
    headerVariant: 'subtitle3',
    textVariant: 'caption',
  };
};
