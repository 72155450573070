import { useRef, TouchEvent } from 'react';

import { getSwipeDirection } from '../utils';

interface Params {
  /** Завершение свайпа */
  onChange: (direction: number) => void;
  /** Движение пальца во время свайпа */
  onMove: (deltaX: number) => void;
}

interface TouchCoords {
  /** Точка начала касания */
  startX: number;
  /** Точка начала касания */
  startY: number;
  /** Точка текущего касания */
  x: number;
  /** Точка текущего касания */
  y: number;
}

/**
 * @description Минимальное расстояние, после которого
 * определяется тип события (cкролл или свайп)
 */
const MIN_DELTA = 10;

/**
 * @description Реализация свайпа
 */
export const useSwipe = ({ onChange, onMove }: Params) => {
  const touchRef = useRef<TouchCoords>({
    startX: 0,
    startY: 0,
    x: 0,
    y: 0,
  });
  const scrollingRef = useRef(false);
  const swipingRef = useRef(false);

  const onTouchStart = (event: TouchEvent) => {
    const touch = event.touches[0];

    Object.assign(touchRef.current, {
      startX: touch.pageX,
      startY: touch.pageY,
      x: touch.pageX,
      y: touch.pageY,
    });
  };

  const onTouchMove = (event: TouchEvent) => {
    if (scrollingRef.current) {
      return;
    }
    const touch = event.touches[0];

    Object.assign(touchRef.current, {
      x: touch.pageX,
      y: touch.pageY,
    });

    const { startX, startY, x, y } = touchRef.current;

    const deltaX = Math.abs(x - startX);
    const deltaY = Math.abs(y - startY);

    if (deltaX < MIN_DELTA && deltaY < MIN_DELTA) {
      return;
    }

    const direction = getSwipeDirection({
      deltaX: startX - x,
      deltaY: startY - y,
    });

    if (!swipingRef.current && !direction) {
      scrollingRef.current = true;
      return;
    }

    swipingRef.current = true;

    onMove(x - startX);
  };

  const onTouchEnd = (event: TouchEvent) => {
    if (swipingRef.current) {
      /**
       * Если был зарегистрирован swipe, отключаем click
       * @see https://w3c.github.io/touch-events/#example-5
       */
      event.preventDefault();
    }
    scrollingRef.current = false;
    swipingRef.current = false;

    const { startX, startY, x, y } = touchRef.current;

    const deltaX = Math.abs(x - startX);
    const deltaY = Math.abs(y - startY);

    if (deltaX < MIN_DELTA && deltaY < MIN_DELTA) {
      return;
    }

    const direction = getSwipeDirection({
      deltaX: startX - x,
      deltaY: startY - y,
    });

    if (!direction) {
      onMove(0);
      return;
    }

    onChange(direction);
  };

  return { onTouchStart, onTouchMove, onTouchEnd };
};
