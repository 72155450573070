import { Link, useParams } from 'react-router-dom';
import { Button, Card, ExpansionPanel, ExpansionPanelContent, Icon, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import cn from 'clsx';
import { FC, useState } from 'react';

import { SuccessVacancyApply } from ':components/SuccessVacancyApply';

import * as styles from './styles.module.scss';

import { Routes } from ':constants';
import {
  MassVacancyForm,
  Modal,
  ProfVacancyForm,
  ServiceError,
  VacancyMainDescription,
  VerticalSteps,
} from ':components';
import { workWithUsBenefits } from ':mocks';
import { useVacancy } from ':hooks';
import { WorkCard } from ':pages/Home/WorkIsBanner/workCard';
import { isDefined, isInternVacancy, isMassVacancy, isProfVacancy } from ':utils';

/**
 * @description Страница Вакансии
 */
export const Vacancy: FC = () => {
  const [open, setOpen] = useState(false);

  const { id: vacancyId } = useParams<{ id: string }>();
  const { data: vacancy, isLoading } = useVacancy(vacancyId);
  const [isSuccessApply, setIsSuccessApply] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = (isOpen: boolean) => {
    setOpen(isOpen);
  };

  const handleSuccessFormSubmit = () => {
    handleSubmit(false);
    setIsSuccessApply(true);
  };

  if (isSuccessApply) {
    return <SuccessVacancyApply />;
  }

  const renderVacancyForm = () => {
    if (!vacancy) {
      return null;
    }

    if (isProfVacancy(vacancy)) {
      return <ProfVacancyForm onClose={handleSubmit} onSuccess={handleSuccessFormSubmit} vacancy={vacancy} />;
    }

    if (isInternVacancy(vacancy)) {
      return <ProfVacancyForm onClose={handleSubmit} onSuccess={handleSuccessFormSubmit} vacancy={vacancy} />;
    }

    if (isMassVacancy(vacancy)) {
      return (
        <MassVacancyForm
          onClose={handleSubmit}
          onSuccess={handleSuccessFormSubmit}
          vacancy={vacancy}
          vacancyName={vacancy.name}
        />
      );
    }

    return null;
  };

  if (isLoading) {
    // todo: сделать скелетон загрузки
    return null;
  }

  if (!isDefined(vacancy)) {
    return <ServiceError variant="500" />;
  }

  const { name, gross_salary_from, description } = vacancy;

  return (
    <div className={styles.vacancyItem}>
      <div className={styles.banner}>
        <div className={styles.bannerContainer}>
          <div className={styles.navigation}>
            <Link className={styles.goBack} to={Routes.Vacancies}>
              <Icon iconName={Icons.ArrowLeft} size="small" />
              <Typography variant="subtitle3">Вернуться в каталог</Typography>
            </Link>
          </div>

          <div className={styles.bannerTitleContainer}>
            <div>
              <Typography className={styles.title} variant="h1">
                {name}
              </Typography>
              <Typography className={styles.subtitle} variant="h4">
                {gross_salary_from} ₽
              </Typography>
            </div>

            <Button className={styles.share} size="large">
              <Icon iconName={Icons.ShareAndroid} />
            </Button>
          </div>

          <VacancyMainDescription loading={isLoading} onClick={handleOpen} vacancy={vacancy} />
        </div>
      </div>

      <div className={styles.description}>
        <div className={styles.vacancyDescription} dangerouslySetInnerHTML={{ __html: description }} />

        {/* <div>
          <Typography variant="h3">Чем предстоит заниматься</Typography>
          <ul className={styles.list}>
            <li>
              <Typography variant="body2">Взаимодействие со стейкхолдерами</Typography>
            </li>
            <li>
              <Typography variant="body2">
                Проведение пользовательских исследований (интервью, CJM, опросы, наблюдение)
              </Typography>
            </li>
            <li>
              <Typography variant="body2">Генерация и проверка гипотез</Typography>
            </li>
            <li>
              <Typography variant="body2">Проектирование UX</Typography>
            </li>
            <li>
              <Typography variant="body2">Прототипирование и UX-тесты</Typography>
            </li>
            <li>
              <Typography variant="body2">Визуальный дизайн</Typography>
            </li>
            <li>
              <Typography variant="body2">Взаимодействие с командой продукта и другими дизайнерами</Typography>
            </li>
            <li>
              <Typography variant="body2">Улучшение показателей продуктовых метрик с помощью UX</Typography>
            </li>
          </ul>
        </div>

        <div>
          <Typography variant="h3">Что предлагаем</Typography>
          <ul className={styles.list}>
            <li>
              <Typography variant="body2">
                Обучение по профилю: как включать клиентов, как настраивать оборудование, как работать с
                электроустановками
              </Typography>
            </li>
            <li>
              <Typography variant="body2">Инструменты, кабель и расходные материалы необходимые для работы</Typography>
            </li>
            <li>
              <Typography variant="body2">Средства индивидуальной защиты</Typography>
            </li>
            <li>
              <Typography variant="body2">
                Компенсация затрат на общественный транспорт (при использовании общественного транспорта)
              </Typography>
            </li>
            <li>
              <Typography variant="body2">
                Полис добровольного медицинского страхования с первого месяца работы
              </Typography>
            </li>
          </ul>
        </div>

        <div>
          <Typography variant="h3">Будет плюсом</Typography>
          <ul className={styles.list}>
            <li>
              <Typography variant="body2">Взаимодействие со стейкхолдерами</Typography>
            </li>
            <li>
              <Typography variant="body2">Навыки графического дизайна</Typography>
            </li>
          </ul>
        </div> */}

        <div className={styles.steps}>
          <div className={cn([styles.step, styles.first])}>
            <div>
              <Typography variant="h3">Как начать работать в билайн</Typography>
              <Typography className={styles.stepDescription} variant="body2">
                Простой и понятный процесс найма, каждый кандидат имеет свой личный кабинет, в котором отражена
                информация о вакансии, этапах отбора и документах
              </Typography>
            </div>

            <Button
              className={cn(styles.button, styles.verticalStepsButton)}
              onClick={handleOpen}
              size="large"
              variant="overlay"
            >
              Откликнуться
            </Button>
          </div>

          <div className={styles.step}>
            <VerticalSteps />
          </div>
        </div>

        <div>
          <Typography className={styles.workWithUsTitle} variant="h3">
            работа в билайне &mdash; это
          </Typography>

          <div className={styles.workWithUs}>
            {workWithUsBenefits.map(({ title, color, icon, subtitleList }) => (
              <WorkCard key={title} color={color} icon={icon} subtitleList={subtitleList} title={title} />
            ))}
          </div>

          <div className={styles.workWithUsAction}>
            <Button onClick={handleOpen} size="large" variant="contained">
              Откликнуться
            </Button>
          </div>
        </div>

        <div className={styles.accordion}>
          <Typography className={styles.accordionTitle} variant="h3">
            Частые вопросы
          </Typography>

          <Card border="default" className={styles.accordionCard}>
            <ExpansionPanel className={styles.expansionPanelTitle} title="Нужен ли опыт работы?">
              <ExpansionPanelContent className={styles.expansionPanelContent}>
                <Typography variant="body2">
                  Мы ценим кандидатов с опытом работы, но если его нет – не страшно, у нас есть оплачиваемое обучение на
                  старте
                </Typography>
              </ExpansionPanelContent>
            </ExpansionPanel>
            <ExpansionPanel className={styles.expansionPanelTitle} title="Как проходит обучение?">
              <ExpansionPanelContent className={styles.expansionPanelContent}>
                <Typography variant="body2">test</Typography>
              </ExpansionPanelContent>
            </ExpansionPanel>
            <ExpansionPanel className={styles.expansionPanelTitle} title="У меня будет карьерный рост?">
              <ExpansionPanelContent className={styles.expansionPanelContent}>
                <Typography variant="body2">test</Typography>
              </ExpansionPanelContent>
            </ExpansionPanel>
          </Card>
        </div>

        <div className={styles.navigation}>
          <Link className={styles.goBack} to={Routes.Vacancies}>
            <Icon iconName={Icons.ArrowLeft} size="small" />
            <Typography variant="subtitle3">Вернуться в каталог</Typography>
          </Link>
        </div>

        <Modal className={styles.modal} open={open}>
          {renderVacancyForm()}
        </Modal>
      </div>
    </div>
  );
};
