import { ParamsSerializer } from './types';

import { stripUndefined } from ':utils';

/**
 * @description Таймаут запроса
 */
export const defaultTimeout = 5000;

/**
 * @description Заголовки, используемые по умолчанию
 */
export const defaultHeaders = {};

/**
 * @description Валидация статуса ответа
 */
export const defaultValidateStatus = (response: Response) => {
  return response.status >= 200 && response.status <= 299;
};

/**
 * @description Дефолтный обработчик параметров запроса
 */
export const defaultParamsSerializer: ParamsSerializer = (params) => {
  return new URLSearchParams(stripUndefined(params)).toString();
};
