import { ChangeEvent, useRef, useState, FocusEvent, useCallback, forwardRef, useImperativeHandle } from 'react';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  /** отключеное состояние */
  disabled?: boolean;
  /** состояние ошибки */
  error?: boolean;
  /** количество цифер */
  length?: number;
  /** возвращает введенный код */
  pinComplete: (code: string) => void;
}

/** Тип для ref компонента */
export interface RefProps {
  /** Функция очистки ввода */
  clear: () => void;
}

/**
 * @description Компонент ввода кода
 */
export const PinCode = forwardRef<RefProps, Props>(({ disabled, error, length = 4, pinComplete }, ref) => {
  const [inputValues, setInputValues] = useState<string[]>(Array(length));
  const inputRefs = useRef(Array(length));

  const clear = useCallback(() => {
    const arr = [];
    for (let i = 0; i < length; i += 1) {
      arr.push('');
    }
    setInputValues(arr);

    inputRefs.current[0]?.focus();
  }, [length]);

  useImperativeHandle(ref, () => {
    return {
      clear,
    };
  }, [clear]);

  const handleInputChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const number = value.replace(/\D/g, '');

    const newArr = [...inputValues];
    newArr[index] = number;
    setInputValues(newArr);

    const code = newArr.join('');
    if (code.length === length) {
      pinComplete(code);
      inputRefs.current[index]?.blur();
      return;
    }

    if (index === length - 1) {
      inputRefs.current[index]?.blur();
      return;
    }

    if (number.length) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const setElement = (index: number) => (element: any) => {
    inputRefs.current[index] = element;
  };

  const elements = Array.from(Array(length).keys());

  return (
    <div className={styles.pinCode}>
      {elements.map((index) => (
        <input
          key={index}
          ref={setElement(index)}
          className={cn(styles.input, disabled && styles.disabled, !disabled && error && styles.error)}
          disabled={disabled}
          maxLength={1}
          onFocus={handleFocus}
          onInput={handleInputChange(index)}
          placeholder="0"
          type="text"
          value={inputValues[index] || ''}
        />
      ))}
    </div>
  );
});
