import { Typography } from '@beeline/design-system-react';

import * as styles from './styles.module.scss';
/**
 *
 * @description Компонент шагов по вакансии
 */
export const VerticalSteps = () => {
  return (
    <ul className={styles.verticalSteps}>
      <li className={styles.item}>
        <span className={styles.text}>
          <Typography className={styles.textLeft} variant="h4">
            Отклик
          </Typography>
          <Typography className={styles.textRight} variant="body2">
            Оставьте отклик на интересную вам вакансию. Если мы увидим подходящие опыт или навыки, то рекрутер свяжется
            для назначения интервью
          </Typography>
        </span>
      </li>
      <li className={styles.item}>
        <span className={styles.text}>
          <Typography className={styles.textLeft} variant="h4">
            Интервью
          </Typography>
          <Typography className={styles.textRight} variant="body2">
            Чтобы попасть в команду, необходимо пройти оценку экспертов, как правило, 1–2 интервью. Мы расскажем
            подробнее о вакансии, а вы зададите вопросы о потенциальной работе. Для некоторых позиций возможно тестовое
            задание
          </Typography>
        </span>
      </li>
      <li className={styles.item}>
        <span className={styles.text}>
          <Typography className={styles.textLeft} variant="h4">
            Финал
          </Typography>
          <Typography className={styles.textRight} variant="body2">
            Подпишите договор и другие документыи начинайте работать! В билайне вы сможете заранее ознакомиться со всеми
            документами в личном кабинете, а также подписать их электронной подписью
          </Typography>
        </span>
      </li>
    </ul>
  );
};
