import { useContext } from 'react';

import { MobileViewContext } from ':components';

/**
 * @description Хук проверки на размер экрана
 */
export const useMobileView = () => {
  return useContext(MobileViewContext);
};
