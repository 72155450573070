import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@beeline/design-system-react';
import cn from 'clsx';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

import { Licence, Modal, MobileId } from ':components';
import MainLogo from ':assets/svg/main-logo-white.svg';
import TelegramLogo from ':assets/svg/telegram.svg';
import VKLogo from ':assets/svg/vk.svg';
import OKLogo from ':assets/svg/ok.svg';

/**
 * @description Компонент подвала сайта
 */
export const Footer = () => {
  /// ///////  Временно, показать mobile ID
  const [mobileIdOpened, setMobileIdOpened] = useState(false);
  const handleMobileIdOpen = () => setMobileIdOpened(true);
  const handleMobileIdClose = () => setMobileIdOpened(false);
  const handleRetry = () => console.log('retry request mobile id code');
  /// ///////

  const navigate = useNavigate();
  const { subtitleVariant, titleVariant } = useVariant();
  const [showLicence, setShowLicence] = useState(false);

  const handleVacanciesClick = () => {
    navigate('/vacancies');
  };

  const handleInternClick = () => {
    navigate('/vacancies');
  };

  const handleLicenceClick = () => setShowLicence(true);

  const handleCloseLicence = () => setShowLicence(false);

  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.description}>
            <div className={styles.logoRow}>
              <Typography className={styles.work} onClick={handleMobileIdOpen} variant="h4">
                работа
              </Typography>
              <img alt="" height="25" src={MainLogo} />
            </div>
            <Typography className={styles.colorGray} variant="body3">
              Сервис поиска работы в билайне
            </Typography>
          </div>
          <div className={styles.workBlock}>
            <Typography className={styles.colorGray} variant={titleVariant}>
              Работа в билайне
            </Typography>
            <Typography
              className={cn(styles.colorBase, styles.linkText)}
              onClick={handleVacanciesClick}
              variant={subtitleVariant}
            >
              Вакансии
            </Typography>
            <Typography
              className={cn(styles.colorBase, styles.linkText)}
              onClick={handleInternClick}
              variant={subtitleVariant}
            >
              Стажировка
            </Typography>
          </div>
          <div className={styles.workBlock}>
            <Typography className={styles.colorGray} variant={titleVariant}>
              Будь в курсе
            </Typography>
            <a href="https://beelinenow.ru/ ">
              <Typography className={styles.colorBase} variant={subtitleVariant}>
                билайн now
              </Typography>
            </a>
            <a href="https://habr.com/ru/companies/beeline_tech/articles/">
              <Typography className={styles.colorBase} variant={subtitleVariant}>
                Habr
              </Typography>
            </a>
            <a href="https://www.youtube.com/@beelineru">
              <Typography className={styles.colorBase} variant={subtitleVariant}>
                Youtube
              </Typography>
            </a>
            <a href="https://rutube.ru/channel/24803281/">
              <Typography className={styles.colorBase} variant={subtitleVariant}>
                Rutube
              </Typography>
            </a>
          </div>
          <div className={styles.workBlock}>
            <Typography className={styles.colorGray} variant={titleVariant}>
              Мы в соцсетях
            </Typography>
            <div className={styles.iconRow}>
              <a className={styles.linkIcon} href="https://t.me/beeline">
                <img alt="telegarm" className={styles.icon} src={TelegramLogo} />
              </a>
              <a className={styles.linkIcon} href="https://vk.com/beeline">
                <img alt="vk" className={styles.icon} src={VKLogo} />
              </a>
              <a className={styles.linkIcon} href="https://ok.ru/beeline">
                <img alt="ok" className={styles.icon} src={OKLogo} />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.copyrights}>
          <div className={styles.licence}>
            <Typography className={cn(styles.colorGray, styles.inline)} variant="body3">
              Продолжая использование сайта, вы соглашаетесь с&nbsp;
            </Typography>
            <Typography className={cn(styles.link, styles.inline)} onClick={handleLicenceClick} variant="body3">
              условиями использования
            </Typography>
          </div>
          <Typography className={cn(styles.defence, styles.colorGray)} variant="body3">
            © 2024, билайн. Все права защищены
          </Typography>
        </div>
      </div>
      <Modal className={styles.licenceModal} open={showLicence}>
        <Licence onClose={handleCloseLicence} />
      </Modal>
      {/* ////////// Временно, показать mobile ID */}
      <Modal className={styles.mobileIdModal} open={mobileIdOpened}>
        <MobileId
          number="+7 (100) 100-10-10"
          onChangeNumber={handleMobileIdClose}
          onClose={handleMobileIdClose}
          onRetry={handleRetry}
          onSuccess={handleMobileIdClose}
        />
      </Modal>
    </div>
  );
};
