import { FC } from 'react';
import { FileUploader, TextArea, TextField, Typography } from '@beeline/design-system-react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

import { useMobileId } from ':hooks/useMobileId';
import { useProfResume } from ':hooks/useProfResume';

import * as styles from './styles.module.scss';
import { schema } from './schema';

import { VacancyFormFooter, Header, VacancyFormHeader, Modal, MobileId, ServiceError } from ':components';
import { BVacancy } from ':types';
import { zodResolver } from '@hookform/resolvers/zod';

type Props = {
  /** Функция закрытия модального окна */
  onClose: (open: boolean) => void;
  /** Функция успешного действия */
  onSuccess: () => void;
  /** Данные по вакансии */
  vacancy: BVacancy;
};

/** Типы полей формы */
type ProfVacancyFormType = z.infer<typeof schema>;

/**
 * @description Компонент отлика на вакансию проф подбора
 */
export const ProfVacancyForm: FC<Props> = ({ onClose, onSuccess, vacancy }) => {
  const {
    isOpen,
    close: handleClose,
    onChangeNumber: handleChangeNumber,
    onRetry: handleRetry,
    onSuccess: handleSuccessId,
  } = useMobileId({ onSuccess });
  const handleClick = () => {
    onClose(false);
  };
  const { mutateAsync: profResumeApply, isError } = useProfResume();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { isSubmitSuccessful, errors },
  } = useForm<ProfVacancyFormType>({
    resolver: zodResolver(schema)
  }
  );

  const onSubmit = async (data: ProfVacancyFormType) => {
    try {
      profResumeApply({
        ...data,
        external_system_vacancy_id: vacancy?.external_system_vacancy_id,
        external_system_name: vacancy?.external_system_name,
      });
    } catch (e) {
      console.error(e);
    } finally {
      handleSuccessId();
    }
  };

  if (isError) {
    return <ServiceError variant="500" />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header className={styles.header} />

      <VacancyFormHeader onClick={handleClick} title="Продуктовый дизайнер в личный кабинет сотрудника" />

      <div className={styles.profVacancyForm}>
        <div className={styles.formWrapper}>
          <div>
            <Typography variant="subtitle1">Контактные данные</Typography>

            <div className={styles.contacts}>
              <TextField fullWidth label="Фамилия*" {...register('last_name', { required: true })} error={!!errors.last_name?.message} helperText={errors.last_name?.message} />
              <TextField fullWidth label="Имя*" {...register('first_name', { required: true })} error={!!errors.first_name?.message} helperText={errors.first_name?.message} /> 
              <TextField fullWidth label="Отчество" {...register('middle_name', {})} />
              <TextField fullWidth label="Ссылка на телеграм" {...register('telegram_url', {})} />
              <TextField
                fullWidth
                label="Телефон*"
                error={!!errors.phone?.message}
                placeholder="+7 (000) 000-00-00"
                helperText={errors.phone?.message || "Мы отправим смс для подтверждения отклика"}
                required
                {...register('phone', { required: true })}
              />
              <TextField fullWidth label="Почта" {...register('email', {})} />
            </div>
          </div>

          <div className={styles.upload}>
            <Typography className={styles.upload_title} variant="subtitle1">
              Прикрепите резюме или добавьте ссылку*
            </Typography>

            <FileUploader
              {...register('resume_file', {})}
              className={styles.uploadInput}
              error={!!errors.resume_file?.message}
              dragOverTitle="Отпустите сюда файлы для загрузки"
              subTitle="pdf, docx, rtf до 10 Мб"
              helperText={errors.resume_file?.message}
              title="Перетащите сюда файл / документ или"
            />

            <TextField fullWidth label="Ссылка на резюме*" helperText={errors.resume_url?.message} {...register('resume_url')} error={!!errors.resume_url?.message} />
            <TextArea
              {...register('cover_letter')}
              className={styles.textarea}
              fullWidth
              helperText="Расскажите о своем опыте и достижениях.
              Добавьте сопроводительное письмо или ссылку на портфолио"
              label="Дополнительная информация"
            />
          </div>

          <Typography variant="caption">* Поля обязательные для заполнения</Typography>
        </div>
      </div>

      <Modal className={styles.mobileIdModal} open={isOpen}>
        <MobileId
          number={getValues().phone}
          onChangeNumber={handleChangeNumber}
          onClose={handleClose}
          onRetry={handleRetry}
          onSuccess={handleSuccessId}
        />
      </Modal>

      <VacancyFormFooter isSubmitting={isSubmitSuccessful} />
    </form>
  );
};
