import { CardProps } from ':pages/Home/WorkIsBanner/workCard';
import message from ':assets/svg/workIs/message.svg';
import star from ':assets/svg/workIs/star.svg';
import people from ':assets/svg/workIs/peoples.svg';
import heart from ':assets/svg/workIs/heart.svg';
import book from ':assets/svg/workIs/book.svg';
import support from ':assets/svg/workIs/support.svg';

export const works: CardProps[] = [
  {
    icon: message,
    color: 'rgb(243, 231, 254)',
    title: 'Связь с миром',
    subtitleList: [
      'Корпоративная связь',
      'Cкидки на домашний интернет и ТВ',
      '250 Гб в облако билайн',
      'Бесплатная связь для близких',
    ],
  },
  {
    icon: star,
    color: 'rgb(255, 244, 225)',
    title: 'Радоваться жизни',
    subtitleList: [
      'Совместные праздники, новогодние подарки для детей ',
      'Скидки от партнеров - SkyEng, СберМаркет, Самокат, Lamoda и другие',
      'Награда "Бриллиантовая пчела" лучшим сотрудниках',
    ],
  },
  {
    icon: people,
    color: 'rgb(255, 236, 239)',
    title: 'Быть среди своих',
    subtitleList: [
      'Сообщества по интересам: кино, шахматы, книги, фитнес, киберспорт',
      'Магазин корпоративного мерча',
      'Спортивные мероприятия',
    ],
  },
  {
    icon: heart,
    color: 'rgb(231, 246, 235)',
    title: 'Заботиться о здоровье',
    subtitleList: [
      'ДМС со стоматологией',
      'Скидки на ДМС для близких',
      'Дополнительно 2 дня отпуска',
      'Доплата по больничному листу',
    ],
  },
  {
    icon: book,
    color: 'rgb(250, 228, 247)',
    title: 'Учиться и расти',
    subtitleList: ['Курсы и программы от билайн университета', 'Коучинг', 'Программы карьерного развития'],
  },
  {
    icon: support,
    color: 'rgb(227, 242, 255)',
    title: 'Получать поддержку',
    subtitleList: ['Страховки от несчастных случаев и в поездках', 'Материальная помощь', 'Корпоративный психолог'],
  },
];
