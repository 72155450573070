import { FC } from 'react';
import { Button, Typography } from '@beeline/design-system-react';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

import { ListItem } from ':components';

interface Props {
  /** Закрытие соглашения */
  onClose: () => void;
}

const list = [
  'тип выполненного на сайте действия (клик, наведение курсора и т. п.);',
  'дата и время выполнения действия;',
  'URL страницы;',
  'URL страницы сайта в сети интернет, с которой был осуществлён переход на сайт или страницу сайта;',
  'IP-адрес;',
  'версия операционной системы пользователя;',
  'ClientID (идентификатор браузера по файлу cookie);',
  'экранное разрешение;',
  'класс HTML-элемента, по которому происходит клик;',
  'данные об информации, просматриваемой в интерфейсе сайта;',
  'данные о фактах заполнения форм/обращений на веб-сайтах ПАО «ВымпелКом», включая ошибки при их заполнении.',
];

const email = 'otvet@beeline.ru';

/**
 * @description Компонент "Условия пользования"
 */
export const Licence: FC<Props> = ({ onClose: handleClose }) => {
  const { headerVariant, textVariant } = useVariant();

  return (
    <div className={styles.licence}>
      <div className={styles.header}>
        <Typography variant={headerVariant}>Условия использования</Typography>
        <Button onClick={handleClose}>Закрыть</Button>
      </div>
      <div className={styles.content}>
        <Typography className={styles.text} variant={textVariant}>
          Вы предоставляете согласие на обработку данных о&nbsp;посещении сайта job.beeline.ru (данные cookies
          и&nbsp;иные пользовательские данные), сбор которых автоматически осуществляется ПАО «ВымпелКом» (Российская
          Федерация, 127083, г. Москва, ул. 8 Марта, д. 10, стр. 14, далее — билайн) на&nbsp;условиях{' '}
          <a className={styles.link} href="https://static.beeline.ru/upload/images/Documents/politica.pdf">
            политики обработки персональных данных.
          </a>
        </Typography>
        <Typography className={styles.text} variant={textVariant}>
          На сайте job.beeline.ru применяются информационные технологии предоставления информации на&nbsp;основе сбора,
          систематизациии анализа сведений, относящихся к&nbsp;предпочтениям пользователей сети «Интернет», находящихся
          на&nbsp;территории Российской Федерации (далее —«Рекомендательные технологии»).
        </Typography>
        <Typography className={styles.text} variant={textVariant}>
          Сбор и обработка данных для применения рекомендательных технологий осуществляются автоматически
          при&nbsp;просмотре вами страниц сайта (из cookies), в&nbsp;том&nbsp;числе:
        </Typography>
        <div className={styles.list}>
          {list.map((text) => (
            <ListItem key={text} text={text} variant={textVariant} />
          ))}
        </div>
        <Typography className={styles.text} variant={textVariant}>
          ПАО «ВымпелКом» также может использовать указанные данные для их&nbsp;последующей обработки системами
          Flocktory, OneSignal, OTM, Mail.ru,
          <br />
          «Яндекс Метрика», Stellarlabs, VK.com, Weborama,и&nbsp;др., а&nbsp;также передавать/привлекать
          к&nbsp;обработке данных третьих лиц, перечень которых размещен{' '}
          <a
            className={styles.link}
            href="https://static.beeline.ru/upload/images/docs/footer_perechen_tretih_lic_dlya_kuki-3003.pdf"
          >
            здесь.
          </a>{' '}
          Обработка осуществляется с целью функционирования сайта job.beeline.ru и&nbsp;продвижения товаров, работ,
          билайна и&nbsp;третьих лиц. Адрес электронной почты ПАО«ВымпелКом»:{' '}
          <a className={styles.link} href={`mailto:${email}`}>
            {email}.
          </a>
        </Typography>
        <Typography className={styles.text} variant={textVariant}>
          ПАО «ВымпелКом» раскрывает информацию, предусмотренную законодательством РФ о&nbsp;ценных бумагах, также
          на&nbsp;веб-странице ООО«Интерфакс-ЦРКИ» — информационного агентства, аккредитованного ЦБ РФ на&nbsp;раскрытие
          информации. Информация доступна по{' '}
          <a className={styles.link} href="https://www.e-disclosure.ru/portal/company.aspx?id=4464">
            ссылке.
          </a>
        </Typography>
      </div>
    </div>
  );
};
