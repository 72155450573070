import { useState } from 'react';
import { HttpHandler } from 'msw';

import { IS_LOCAL_MOCK } from ':constants';
import { enableMocking } from ':utils';

/**
 * @description Подготавливает моки и возвращает статус об их готовности
 * @param handlers Массив обработчиков запросов
 */
export const useReadyMocks = (handlers: HttpHandler[]) => {
  const [isMocksReady, setIsMocksReady] = useState(!IS_LOCAL_MOCK || !handlers.length);

  if (IS_LOCAL_MOCK && !isMocksReady) {
    enableMocking(handlers).then(() => setIsMocksReady(true));
  }

  return isMocksReady;
};
