import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { API } from ':api';
import { BWorkGrade } from ':types';

/**
 * @description Загрузка фильтров по грейдам
 */
export const useGrade = () => {
  return useQuery<BWorkGrade[]>({
    queryKey: ['grade'],
    queryFn: () => {
      return API.get(Endpoints.grade());
    },
    initialData: [],
  });
};
