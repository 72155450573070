import { BVacancy } from ':types';

/**
 * @description Мок ЦПК (массовые), Монобренд, ШПД вакансии
 */
export const MassVacancyMock: BVacancy = {
  id: '21',
  name: 'Менеджер по поддержке клиентов',
  city: ['Санкт-Петербург', 'Москва'],
  grade: 'Более 5 лет',
  gross_salary: 'от 56 000 до 87 000  ₽',
  gross_salary_from: 'от 87 000',
  work_format: 'Офис',
  format: 'Офис',
  description: '',
  direction: 'IT',
  is_active: true,
  job_type_id: '4',
};

/**
 * @description Мок вакансии стажера
 */
export const InternVacancyMock: BVacancy = {
  id: '21',
  name: 'Продавец-консультант',
  city: ['Санкт-Петербург', 'Москва'],
  grade: 'Более 5 лет',
  gross_salary: 'от 56 000 до 87 000  ₽',
  gross_salary_from: 'от 87 000',
  work_format: 'Офис',
  format: 'Офис',
  description: '',
  direction: 'IT',
  is_active: true,
  job_type_id: '4',
};

/**
 * @description Мок вакансии ЦПК (точечные), проф. подбора
 */
export const ProfVacancyMock: BVacancy = {
  id: '22',
  name: 'Продавец-консультант',
  city: ['Санкт-Петербург', 'Москва'],
  grade: 'Более 5 лет',
  gross_salary: 'от 56 000 до 87 000  ₽',
  format: 'Офис',
  gross_salary_from: 'от 87 000',
  work_format: 'Офис',
  description: '',
  direction: 'IT',
  is_active: true,
  job_type_id: '4',
};
