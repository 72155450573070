import { TypographyVariant } from '@beeline/design-system-react';

import { useMobileView } from ':hooks/useMobileView';

interface VariantType {
  /** Кнопка во всю ширину */
  fullButton: boolean;
  /** Вариант subtitle */
  subtitleVariant: TypographyVariant;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isLargeViewUp } = useMobileView();

  if (isLargeViewUp) {
    return {
      fullButton: false,
      titleVariant: 'h2',
      subtitleVariant: 'body2',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      fullButton: false,
      titleVariant: 'h3',
      subtitleVariant: 'body3',
    };
  }

  return {
    fullButton: true,
    titleVariant: 'h4',
    subtitleVariant: 'body3',
  };
};
