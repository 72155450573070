import { useLayoutEffect } from 'react';

import { useEventCallback } from ':hooks';

/**
 * @description Обработка изменения ширины слайдера
 */
export const useResize = (node: HTMLElement | null, cb: () => void) => {
  const callback = useEventCallback(cb);

  useLayoutEffect(() => {
    if (!node) {
      return undefined;
    }
    const observer = new ResizeObserver(() => {
      callback();
    });

    observer.observe(node);

    return () => {
      observer.unobserve(node);
    };
  }, [node, callback]);
};
