import { TypographyVariant } from '@beeline/design-system-react';
import {
  ButtonColorVariants,
  ButtonSizeVariants,
} from '@beeline/design-system-react/types/components/Button/Button.types';

import { useMobileView } from ':hooks/useMobileView';

interface VariantType {
  /** Размер кнопки */
  buttonSize: ButtonSizeVariants;
  /** Вариант кнопки */
  buttonVariant: ButtonColorVariants;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов в зависимости от размера экрана */
export const useVariant = (): VariantType => {
  const { isMobilePlusViewUp, isLargeViewUp } = useMobileView();

  if (isLargeViewUp) {
    return {
      buttonSize: 'large',
      buttonVariant: 'contained',
      titleVariant: 'h2',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      buttonSize: 'large',
      buttonVariant: 'contained',
      titleVariant: 'h3',
    };
  }

  return {
    buttonSize: 'medium',
    buttonVariant: 'overlay',
    titleVariant: 'h4',
  };
};

interface CardVariantType {
  /** Вариант subtitle */
  subtitleVariant: TypographyVariant;
  /** Вариант title */
  titleVariant: TypographyVariant;
}

/** Хук для отображения варианта компонентов карточки в зависимости от размера экрана */
export const useCardVariant = (): CardVariantType => {
  const { isMobilePlusViewUp, isLargeViewUp } = useMobileView();

  if (isLargeViewUp) {
    return {
      subtitleVariant: 'body2',
      titleVariant: 'h4',
    };
  }

  if (isMobilePlusViewUp) {
    return {
      subtitleVariant: 'body3',
      titleVariant: 'subtitle1',
    };
  }

  return {
    subtitleVariant: 'body3',
    titleVariant: 'subtitle1',
  };
};
