import { BVacancy } from ':types';
import { JobTypes } from ':constants';

/**
 * @param value Значение
 * @returns Значение не null и undefined
 */
export const isDefined = <T>(value: T | null | undefined): value is T => {
  return value !== undefined && value !== null;
};

/**
 * @description Удаление null и undefined значений в объекте
 */
export const stripUndefined = (obj: Record<string, any>) => {
  const filteredEntries = Object.entries(obj).filter((entry) => isDefined(entry[1]));
  return Object.fromEntries(filteredEntries);
};

/**
 * @description Добавление параметров к url
 */
export const concatParams = (url: string, paramsQuery: string) => {
  const divider = url.includes('?') ? '&' : '?';
  return `${divider}${paramsQuery}`;
};

/**
 * @description Проверка на объект
 * @see https://github.com/reduxjs/redux/blob/master/src/utils/isPlainObject.ts
 */
export function isPlainObject(obj: any): obj is object {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }

  let proto = obj;
  while (Object.getPrototypeOf(proto) !== null) {
    proto = Object.getPrototypeOf(proto);
  }

  return Object.getPrototypeOf(obj) === proto || Object.getPrototypeOf(obj) === null;
}

/**
 * @description Проверка возможности конвертации в json
 */
export const isJsonifiable = (body: any) => {
  return typeof body === 'object' && (isPlainObject(body) || Array.isArray(body));
};

/**
 * @description Проверка типа вакансии
 */
export const isProfVacancy = (vacancy: BVacancy) => {
  return JobTypes.Prof.includes(vacancy.job_type_id);
};

/**
 * @description Проверка типа вакансии
 */
export const isInternVacancy = (vacancy: BVacancy) => {
  return JobTypes.Intern.includes(vacancy.job_type_id);
};

/**
 * @description Проверка типа вакансии
 */
export const isMassVacancy = (vacancy: BVacancy) => {
  return JobTypes.Mass.includes(vacancy.job_type_id);
};
