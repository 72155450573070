import { ButtonProps, TypographyProps } from '@beeline/design-system-react';

import { useMobileView } from ':hooks/useMobileView';

interface ReturnType {
  /** Вариант кнопки */
  buttonSize: ButtonProps['size'];
  /** Брейкпоинт для широких экранов */
  isLargeViewUp: boolean;
  /** Вариант загловка */
  subtitleVariant: TypographyProps['variant'];
  /** Вариант подзаголовка */
  titleVariant: TypographyProps['variant'];
}

/**
 * @description Получение варианта отображения
 */
export const useVariant = (): ReturnType => {
  const { isMobileViewUp, isLargeViewUp } = useMobileView();

  const base = {
    isLargeViewUp,
  };

  if (isLargeViewUp) {
    return {
      ...base,
      titleVariant: 'h2',
      subtitleVariant: 'body2',
      buttonSize: 'large',
    };
  }

  if (isMobileViewUp) {
    return {
      ...base,
      titleVariant: 'h3',
      subtitleVariant: 'body2',
      buttonSize: 'medium',
    };
  }

  return {
    ...base,
    titleVariant: 'h4',
    subtitleVariant: 'body3',
    buttonSize: 'medium',
  };
};
