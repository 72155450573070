import { FC, PropsWithChildren, useEffect } from 'react';
import { createPortal } from 'react-dom';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  /** стиль модального окна */
  className?: string;
  /** показать модальное окно */
  open: boolean;
}

export const Modal: FC<PropsWithChildren<Props>> = ({ children, className, open }) => {
  const rootElement = document.getElementById('root');

  useEffect(() => {
    // TODO Roman rezise => !open
    if (open) {
      document.body.classList.add(styles.hideScroll);
    }

    if (!open) {
      document.body.classList.remove(styles.hideScroll);
    }

    return () => {
      document.body.classList.remove(styles.hideScroll);
    };
  }, [open]);

  if (!open || !rootElement) {
    return null;
  }

  return createPortal(<div className={cn(styles.modal, className)}>{children}</div>, rootElement);
};
