import { CSSProperties, FC, KeyboardEvent, ReactElement, TransitionEvent, useRef, useState } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';
import { Slides } from './Slides';
import { getTrackStyle } from './utils';
import { useResize, useSwipe } from './hooks';

interface SlideProps {
  /**
   * Флаг отключения анимаций.
   *
   * Необходимо отключать все анимации при переносе
   * выбранного слайда с клона на соответствующий ему оригинал
   */
  disableAnimations: boolean;
  /** Флаг выбранного слайда */
  isSelected: boolean;
}

export interface Slide {
  /** Функция для рендера слайда */
  render: (props: SlideProps) => ReactElement;
}

interface Props {
  /** Массив слайдов */
  slides: Slide[];
}

/**
 * @description Компонент слайдер
 * Основан на react-slick, добавлены анимации, сохранены отступы у выбранного элемента
 * @see https://github.com/akiran/react-slick/blob/master/src/inner-slider.js
 */
export const Slider: FC<Props> = ({ slides }) => {
  const { length } = slides;
  const [selectedIndex, setSelectedIndex] = useState(slides.length);
  const [trackElement, setTrackElement] = useState<HTMLElement | null>(null);
  const [trackStyle, setTrackStyle] = useState<CSSProperties>({});
  const [isAnimationsDisabled, setIsAnimationsDisabled] = useState(true);
  const animatingRef = useRef(false);

  const changeSlide = (index: number, disableAnimations = false) => {
    if (index === selectedIndex || !trackElement || animatingRef.current) {
      return;
    }
    animatingRef.current = !disableAnimations;
    setIsAnimationsDisabled(disableAnimations);
    setSelectedIndex(index);

    setTrackStyle(getTrackStyle({ trackElement, selectedIndex: index }));
  };

  const swipeProps = useSwipe({
    onChange: (direction) => {
      changeSlide(selectedIndex - direction);
    },
    onMove: (offset) => {
      if (animatingRef.current || !trackElement) {
        return;
      }

      setIsAnimationsDisabled(true);
      setTrackStyle(getTrackStyle({ selectedIndex, trackElement, offset }));
    },
  });

  useResize(trackElement, () => {
    setIsAnimationsDisabled(true);
    setTrackStyle(getTrackStyle({ trackElement: trackElement!, selectedIndex }));
  });

  const handleSlideClick = (key: number) => () => {
    changeSlide(key);
  };

  const handleTransitionEnd = (event: TransitionEvent) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    animatingRef.current = false;

    if (selectedIndex < length) {
      changeSlide(selectedIndex + length, true);
      return;
    }

    if (selectedIndex >= length * 2) {
      changeSlide(selectedIndex - length, true);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    const { key } = event;

    if (key === 'ArrowLeft') {
      changeSlide(selectedIndex - 1);
    }

    if (key === 'ArrowRight') {
      changeSlide(selectedIndex + 1);
    }
  };

  return (
    <div className={styles.slider}>
      <div
        ref={setTrackElement}
        className={clsx(styles.track, isAnimationsDisabled && styles.disableAnimations)}
        onKeyDown={handleKeyDown}
        onTransitionEnd={handleTransitionEnd}
        role="presentation"
        style={trackStyle}
        {...swipeProps}
      >
        <Slides
          disableAnimations={isAnimationsDisabled}
          onClick={handleSlideClick}
          selectedIndex={selectedIndex}
          slides={slides}
        />
      </div>
    </div>
  );
};
