import { Button, Card, Skeleton, Typography } from '@beeline/design-system-react';
import { FC } from 'react';

import { isDefined } from ':utils/common';

import * as styles from './styles.module.scss';

import { BVacancy } from ':types';

type VacancyMainDescriptionProps = {
  /** параметр загрузки данных */
  loading?: boolean;
  /** функция открытия модалки отклика */
  onClick?: () => void;
  /** Данные по вакансии */
  vacancy?: BVacancy;
};

/**
 *
 * @description Компонент описания для детализации вакансии
 */
export const VacancyMainDescription: FC<VacancyMainDescriptionProps> = ({ loading, onClick: handleClick, vacancy }) => {
  if (loading || !isDefined(vacancy)) {
    return (
      <Card className={styles.vacancyMainDescription} elevation="low">
        <div className={styles.skeleton}>
          <Skeleton height={18} radius={12} width={120} />
          <Skeleton height={22} radius={12} />
        </div>

        <div className={styles.skeleton}>
          <Skeleton height={18} radius={12} width={120} />
          <Skeleton height={22} radius={12} />
        </div>

        <div className={styles.skeleton}>
          <Skeleton height={18} radius={12} width={120} />
          <Skeleton height={22} radius={12} />
        </div>

        <div className={styles.skeleton}>
          <Skeleton height={18} radius={12} width={120} />
          <Skeleton height={22} radius={12} />
        </div>
      </Card>
    );
  }

  const { city, format, grade, direction } = vacancy;

  return (
    <Card className={styles.vacancyMainDescription} elevation="low">
      <div>
        <Typography className={styles.cardTitle} variant="body3">
          Город
        </Typography>
        <Typography variant="body2">{city.join(', ') || '-'}</Typography>
      </div>

      <div>
        <Typography className={styles.cardTitle} variant="body3">
          Формат работы
        </Typography>
        <Typography variant="body2">{format}</Typography>
      </div>

      <div>
        <Typography className={styles.cardTitle} variant="body3">
          Опыт работы
        </Typography>
        <Typography variant="body2">{grade}</Typography>
      </div>

      <div>
        <Typography className={styles.cardTitle} variant="body3">
          Направление
        </Typography>
        <Typography variant="body2">{direction}</Typography>
      </div>

      <Button onClick={handleClick} size="large" variant="contained">
        Откликнуться
      </Button>
    </Card>
  );
};
