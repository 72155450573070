import { Button, Card, Checkbox, Icon, Search, Select, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { generatePath } from 'react-router-dom';

import { NoVacanciesFound } from ':components/NoVacanciesFound';

import styles from './styles.module.scss';
import { useChangeSearchParams, useVacanciesData } from './hooks';

import { Routes } from ':constants';
import { CheckboxTree, VacancyCard } from ':components';
import { useDelay } from ':hooks';
import { BCity, BWorkFormat, BWorkGrade } from ':types';

/**
 * @description Страница Вакансий
 */
export const Vacancies = () => {
  const {
    cities,
    directions,
    isDisability,
    isInternship,
    selectedCities,
    selectedGrades,
    selectedRoles,
    selectedWorkFormat,
    vacancies,
    workFormats,
    workGrade,
  } = useVacanciesData();

  const { handleCheckboxChange, handleCheckboxTreeChange, handleSelectChange } = useChangeSearchParams();

  const isLoading = useDelay();

  const handleMakeOption = (value: BCity | BWorkGrade | BWorkFormat) => {
    return <span>{value.name}</span>;
  };

  const handleRenderValue = (values: BCity[] | BWorkGrade[] | BWorkFormat[]) => {
    return values.map(({ name }) => name).join(', ');
  };

  return (
    <div className={styles.vacancies}>
      <div className={styles.title}>
        <Typography variant="h1">Вакансии</Typography>

        <div className={styles.searchControls}>
          <Search
            className={styles.mainbanner_search}
            fullWidth
            placeholder="Введите название вакансии или специализации"
          />

          <Button className={styles.search_button} size="small">
            <Icon iconName={Icons.Filter} />
          </Button>
        </div>
      </div>

      <div className={styles.container}>
        <Card border="default" className={styles.search}>
          <Select
            className={styles.search_input}
            label="Город"
            makeOption={handleMakeOption}
            multiple
            onChange={handleSelectChange('cities')}
            options={cities}
            renderValue={handleRenderValue}
            values={selectedCities}
          />
          <Select
            className={styles.search_input}
            label="Опыт работы"
            makeOption={handleMakeOption}
            multiple
            onChange={handleSelectChange('grades')}
            options={workGrade}
            renderValue={handleRenderValue}
            values={selectedGrades}
          />
          <Select
            className={styles.search_input}
            label="Формат работы"
            makeOption={handleMakeOption}
            multiple
            onChange={handleSelectChange('format')}
            options={workFormats}
            renderValue={handleRenderValue}
            values={selectedWorkFormat}
          />

          <Typography className={styles.navigation} variant="subtitle1">
            Направления
          </Typography>

          {directions && directions.length > 0 && (
            <CheckboxTree
              directions={directions}
              onChange={handleCheckboxTreeChange}
              values={selectedRoles.map(({ role_id: roleId }) => roleId)}
            />
          )}

          <div className={styles.additionalFilters}>
            <Checkbox
              checked={isDisability}
              className={styles.checkbox}
              label="Для людей с ограниченными возможностями здоровья"
              onChange={handleCheckboxChange('disability')}
            />
            <Checkbox
              checked={isInternship}
              className={styles.checkbox}
              label="Стажировка"
              onChange={handleCheckboxChange('internship')}
            />
          </div>

          <Button className={styles.resetButton} variant="plain">
            Сбросить фильтры
          </Button>
        </Card>

        {vacancies?.results.length === 0 && <NoVacanciesFound />}

        {vacancies?.results.length > 0 && (
          <div className={styles.cards}>
            {vacancies?.results.map((vacancy) => (
              <VacancyCard
                {...vacancy}
                key={vacancy.id}
                href={generatePath(Routes.Vacancy, { id: vacancy.id })}
                loading={isLoading}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
