import { FC } from 'react';
import { Card, Chip, Icon, Typography, Button, Skeleton } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

type VacanciesCardProps = {
  city: string[];
  grade: string;
  gross_salary_from: string;
  href: string;
  loading?: boolean;
  name: string;
  role: string;
  work_format: string;
};

/**
 * @description Карточка вакансии
 */
export const VacancyCard: FC<VacanciesCardProps> = ({
  name,
  work_format,
  gross_salary_from,
  href,
  grade,
  role,
  city,
  loading,
}) => {
  const cities = city.join(', ');

  if (loading) {
    return (
      <Link className={styles.link} to={href}>
        <Card className={styles.card} elevation="low">
          <div className={styles.cardTitle}>
            <Skeleton height={32} radius={12} width={383} />
            <Skeleton height={32} radius={12} width={32} />
          </div>

          <div className={styles.skeletonBullets}>
            <Skeleton height={32} radius={12} width={120} />
            <Skeleton height={32} radius={12} width={120} />
            <Skeleton height={32} radius={12} width={120} />
            <Skeleton height={32} radius={12} width={120} />
          </div>
        </Card>
      </Link>
    );
  }

  return (
    <Link className={styles.link} to={href}>
      <Card className={styles.card} elevation="low">
        <div className={styles.cardTitle}>
          <div className={styles.vacancyDescription}>
            <Typography variant="h4">{name}</Typography>

            <Typography className={styles.price} variant="subtitle2">
              {gross_salary_from} ₽
            </Typography>
          </div>

          <Chip label={role} />
        </div>

        <div className={styles.controls}>
          <div className={styles.tags}>
            {work_format && (
              <div className={styles.tag}>
                <Icon iconName={Icons.Map} />
                <Typography variant="body2">{work_format}</Typography>
              </div>
            )}

            {grade && (
              <div className={styles.tag}>
                <Icon iconName={Icons.Suitcase} />
                <Typography variant="body2">{grade}</Typography>
              </div>
            )}

            {cities && (
              <div className={styles.tag}>
                <Icon iconName={Icons.Pin} />
                <Typography variant="body2">{cities}</Typography>
              </div>
            )}
          </div>

          <Button className={styles.button} variant="contained">
            Посмотреть вакансию
          </Button>
        </div>
      </Card>
    </Link>
  );
};
