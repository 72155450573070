import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Icon, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import cn from 'clsx';

import { ListItem } from ':components/ListItem';

import styles from './styles.module.scss';
import { useVariantCard } from './hooks';

interface Props {
  /** Цвет */
  color: string;
  /** Картинка */
  image?: string;
  /** Список subtitle */
  subtitleList: string[][];
  /** Title */
  title: string;
}

/**
 * @description Карточка вакансии
 */
export const VacancyCard: FC<Props> = ({ color, image, subtitleList, title }) => {
  const navigate = useNavigate();
  const { displayContent, titleVariant } = useVariantCard();

  const handleVacancyClick = () => {
    navigate('/vacancies');
  };

  return (
    <div className={cn(styles.vacancyCard, image && styles.vacancyBigCard)} style={{ backgroundColor: color }}>
      <div className={styles.content}>
        <Typography className={styles.title} variant={titleVariant}>
          {title}
        </Typography>
        {displayContent && (
          <div className={styles.list}>
            {subtitleList.map((rowList, index) => (
              <div key={index} className={styles.row}>
                {rowList.map((item) => (
                  <ListItem key={item} className={styles.item} text={item} />
                ))}
              </div>
            ))}
          </div>
        )}
        <Button className={styles.button} href="/" onClick={handleVacancyClick} size="small" variant="overlay">
          К вакансиям <Icon className={styles.buttonText} iconName={Icons.ArrowRight} />
        </Button>
      </div>
      {displayContent && image && <img alt={image} className={styles.image} src={image} />}
    </div>
  );
};
