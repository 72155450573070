import { useEffect, useRef } from 'react';

/**
 * @description Имитация useEffectEvent
 * @see https://react.dev/learn/separating-events-from-effects#declaring-an-effect-event
 */
export const useEventCallback = <T extends unknown[], R>(callback: (...args: T) => R) => {
  const callbackRef = useRef(callback);
  const eventCallbackRef = useRef((...args: T): R => {
    return callbackRef.current(...args);
  });

  useEffect(() => {
    callbackRef.current = callback;
  });

  return eventCallbackRef.current;
};
