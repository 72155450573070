import { Checkbox, IconButton, Typography } from '@beeline/design-system-react';
import { ChangeEvent, FC, useState } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import cn from 'clsx';

import styles from './styles.module.scss';

import { BRole } from ':types';
import { Collapse } from ':components';

interface Props {
  /** Название направления */
  directionName: string;
  /** Коллбек изменения */
  onChange: (selectedIds: string[]) => void;
  /** Массив всех ролей */
  roles: BRole[];
  /** Массив идентификаторов выбранных ролей */
  values: string[];
}

/**
 * @description Компонент дерева чекбоксов
 */
export const Direction: FC<Props> = ({ directionName, roles, values: selectedIds, onChange }) => {
  const roleIds = roles.map(({ role_id: roleId }) => roleId);
  const [open, setOpen] = useState(false);
  const isEvery = roleIds.every((roleId) => selectedIds.includes(roleId));
  const isSome = roleIds.some((roleId) => selectedIds.includes(roleId));
  const isPartial = isSome && !isEvery;

  const handleDirectionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      const nextSelectedIds = new Set([...selectedIds, ...roleIds]);
      onChange([...nextSelectedIds]);
    } else {
      onChange(selectedIds.filter((selectedId) => !roleIds.includes(selectedId)));
    }
  };

  const handleRoleChange = (roleId: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    if (checked) {
      onChange([...selectedIds, roleId]);
    } else {
      onChange(selectedIds.filter((selectedId) => selectedId !== roleId));
    }
  };

  const handleIconClick = () => {
    setOpen(!open);
  };

  return (
    <div className={cn(styles.direction, open && styles.open)}>
      <div className={styles.title}>
        <IconButton className={styles.icon} iconName={Icons.NavArrowRight} onClick={handleIconClick} size="medium" />
        <Checkbox
          checked={isSome}
          label={directionName}
          onChange={handleDirectionChange}
          type={isPartial ? 'indeterminate' : 'checkbox'}
        />
      </div>

      <Collapse open={open}>
        <div className={styles.content}>
          {roles.map(({ role_id: roleId, role_name: roleName, role_count: roleCount }) => {
            return (
              <div key={roleId} className={styles.role}>
                <Checkbox checked={selectedIds.includes(roleId)} label={roleName} onChange={handleRoleChange(roleId)} />
                <Typography variant="caption">{roleCount}</Typography>
              </div>
            );
          })}
        </div>
      </Collapse>
    </div>
  );
};
