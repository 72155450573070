import { GetSwipeAngleParams, getSwipeAngle } from './getSwipeAngle';
import { isInRange } from './isInRange';

type Direction =
  /** Влево */
  | -1
  /** Вертикально */
  | 0
  /** Вправо */
  | 1;

/**
 * @description Получение направления свайпа
 */
export const getSwipeDirection = (params: GetSwipeAngleParams): Direction => {
  const angle = getSwipeAngle(params);

  if (isInRange(angle, [0, 45], [315, 360])) {
    return -1;
  }

  if (isInRange(angle, [135, 225])) {
    return 1;
  }

  return 0;
};
