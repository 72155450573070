import dataBase from ':assets/img/data_base.png';
import man from ':assets/img/man.png';
import roznica from ':assets/img/rozniza.png';
import message from ':assets/svg/workIs/message.svg';
import star from ':assets/svg/workIs/star.svg';
import people from ':assets/svg/workIs/peoples.svg';
import heart from ':assets/svg/workIs/heart.svg';
import book from ':assets/svg/workIs/book.svg';
import support from ':assets/svg/workIs/support.svg';
import { BVacancyPage } from ':types';

interface Vacancy {
  color: string;
  image?: any;
  subtitleList: string[][];
  title: string;
}

const adaptiveVacancy = [
  {
    title: 'Инженерия объектов связи',
    color: 'rgb(227, 242, 255)',
    subtitleList: [
      ['Эксплуатация сети'],
      ['Аренда позиций', 'Энергетика'],
      ['Строительство сети'],
      ['Монтаж оборудования'],
    ],
  },
  {
    title: 'Инженерия объектов связи',
    color: 'rgb(227, 242, 255)',
    subtitleList: [['Аренда позиций', 'Энергетика'], ['Строительство и эксплуатация сети'], ['Монтаж оборудования']],
  },
];

const mockVacancies: Vacancy[] = [
  {
    title: 'ИТ-решения и\xa0продукты',
    color: 'rgb(243, 231, 254)',
    subtitleList: [['Разработка', 'Аналитика', 'QA'], ['Управление продуктами'], ['Дизайн', 'BigData']],
    image: dataBase,
  },
  {
    title: 'Развитие бизнеса и\xa0продаж',
    color: 'rgb(231, 246, 235)',
    subtitleList: [['Продажи В2В', 'Продажи В2C'], ['Маркетинг', 'Реклама', 'PR'], ['Высший менеджмент']],
  },
  {
    title: 'Контактные центры',
    color: 'rgb(224, 247, 251)',
    subtitleList: [
      ['Поддержка клиентов', 'Поддержка В2В клиентов'],
      ['Техническая поддержка', 'Без опыта'],
      ['Телепродажи', 'Менеджмент'],
    ],
    image: man,
  },
  {
    title: 'Офисы продаж',
    color: '  rgb(255, 247, 215)',
    subtitleList: [['Розничные продажи'], ['Без опыта работы'], ['Офисы продаж']],
    image: roznica,
  },
  {
    title: 'Продажи домашнего интернета и\xa0ТВ',
    color: '  rgb(255, 236, 239)',
    subtitleList: [['Без опыта', 'Подработка'], ['Агенты', 'Промоутеры'], ['Активные продажи']],
  },
];

export const vacancies = (isDesktop: boolean) => {
  const result = [...mockVacancies];
  result.splice(1, 0, adaptiveVacancy[isDesktop ? 1 : 0]);
  return result;
};

export const workWithUsBenefits = [
  {
    icon: message,
    color: 'rgb(243, 231, 254)',
    title: 'Связь с миром',
    subtitleList: [
      'Корпоративная связь',
      'Cкидки на домашний интернет и ТВ',
      '250 Гб в облако билайн',
      'Бесплатная связь для близких',
    ],
  },
  {
    icon: star,
    color: 'rgb(255, 244, 225)',
    title: 'Радоваться жизни',
    subtitleList: [
      'Совместные праздники, новогодние подарки для детей ',
      'Скидки от партнеров - SkyEng, СберМаркет, Самокат, Lamoda и другие',
      'Награда "Бриллиантовая пчела" лучшим сотрудниках',
    ],
  },
  {
    icon: people,
    color: 'rgb(255, 236, 239)',
    title: 'Быть среди своих',
    subtitleList: [
      'Сообщества по интересам: кино, шахматы, книги, фитнес, киберспорт',
      'Магазин корпоративного мерча',
      'Спортивные мероприятия',
    ],
  },
  {
    icon: heart,
    color: 'rgb(231, 246, 235)',
    title: 'Заботиться о здоровье',
    subtitleList: [
      'ДМС со стоматологией',
      'Скидки на ДМС для близких',
      'Дополнительно 2 дня отпуска',
      'Доплата по больничному листу',
    ],
  },
  {
    icon: book,
    color: 'rgb(250, 228, 247)',
    title: 'Учиться и расти',
    subtitleList: ['Курсы и программы от билайн университета', 'Коучинг', 'Программы карьерного развития'],
  },
  {
    icon: support,
    color: 'rgb(227, 242, 255)',
    title: 'Получать поддержку',
    subtitleList: ['Страховки от несчастных случаев и в поездках', 'Материальная помощь', 'Корпоративный психолог'],
  },
];

export const VacanciesPageMock: BVacancyPage = {
  count: 7,
  next: 'http://127.0.0.1:8000/api/v1/vacancies/?limit=2&offset=2',
  previous: null,
  results: [
    {
      id: '21',
      name: 'devops инженер',
      city: ['Самара', 'Москва'],
      grade: 'Более 5 лет',
      gross_salary_from: '260.000 руб.',
      role: 'Devops',
      job_type_id: '1',
      work_format: 'Дистанционный формат',
    },
    {
      id: '22',
      name: 'devops инженер2',
      city: ['Москва'],
      grade: 'Более 5 лет',
      gross_salary_from: '260.000 руб.',
      role: 'Devops',
      job_type_id: '5',
      work_format: 'Гибридный формат',
    },
  ],
};
