import { useState } from 'react';

type Props = {
  /** Колбек успешного действия */
  onSuccess?: () => void;
};

/**
 *
 * @description Хук колбеков для модального окна авторизации
 */
export const useMobileId = (props?: Props) => {
  const [mobileIdOpened, setMobileIdOpened] = useState(false);
  const handleMobileIdOpen = () => setMobileIdOpened(true);
  const handleMobileIdClose = () => setMobileIdOpened(false);
  const handleRetry = () => console.log('retry request mobile id code');

  const onSuccess = () => {
    props?.onSuccess?.();
    handleMobileIdClose();
  };

  return {
    isOpen: mobileIdOpened,
    onClose: handleMobileIdClose,
    onSuccess,
    onRetry: handleRetry,
    onChangeNumber: handleMobileIdClose,
    close: handleMobileIdClose,
    open: handleMobileIdOpen,
  };
};
