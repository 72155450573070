import { CSSProperties } from 'react';

import { getWidth } from './getWidth';

interface Params {
  /** Дополнительное смещение */
  offset?: number;
  /** Индекс выбранного слайда */
  selectedIndex: number;
  /** Элемент трека */
  trackElement: HTMLElement;
}

/**
 * @description Получение конечного смещения трека
 */
export const getTrackStyle = ({ trackElement, selectedIndex, offset = 0 }: Params): CSSProperties => {
  const trackWidth = getWidth(trackElement);
  const slideWidth = getWidth(trackElement.children[0]);

  const left = slideWidth * selectedIndex + slideWidth / 2 - trackWidth / 2 - offset;

  return { transform: `translateX(-${left}px)` };
};
