import { HttpHandler } from 'msw';
import { setupWorker } from 'msw/browser';

import { IS_LOCAL_MOCK, IS_TEST_ENV } from ':constants';

export * from './common';

/**
 * @description Инициализация MSW
 */
export const enableMocking = async (handlers: HttpHandler[]) => {
  if (!IS_LOCAL_MOCK || IS_TEST_ENV) {
    return;
  }
  const worker = setupWorker(...handlers);
  await worker.start();
};
