import { matchPath, useLocation } from 'react-router-dom';

import { TabsInfo } from ':constants';

/**
 * @description Получение индекса текущего таба
 */
export const useSelectedTabIndex = () => {
  const { pathname } = useLocation();

  return Object.values(TabsInfo).findIndex(({ routes }) => {
    return routes.some((route) => matchPath(route, pathname));
  });
};
