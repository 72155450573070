import { Button, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

import evolution from ':assets/img/evolution.png';

/**
 * @description Блок "Учись и равивайся""
 */
export const EvolutionBlock = () => {
  const navigate = useNavigate();
  const { fullButton, titleVariant, subtitleVariant } = useVariant();

  const handleRequestClick = () => {
    navigate('/vacancies');
  };

  return (
    <div className={styles.evolutionBlock}>
      <img alt="evolution" className={styles.image} src={evolution} />
      <Typography className={styles.title} variant={titleVariant}>
        учись и развивайся
        <br />
        на реальных задачах
      </Typography>
      <Typography className={styles.subtitle} variant={subtitleVariant}>
        Мы предлагаем стажировку студентам старших курсов и&nbsp;выпускникам в&nbsp;разных направлениях: ИТ, аналитика,
        техника, продажи и&nbsp;другое.
        <br />
        Это возможность для&nbsp;студентов получить ценный опыт, поработать в&nbsp;команде профессионалов
        и&nbsp;развиваться вместе с&nbsp;нами
      </Typography>
      <div className={styles.button}>
        <Button fullWidth={fullButton} onClick={handleRequestClick} size="large" variant="overlay">
          Подать заявку на стажировку
        </Button>
      </div>
    </div>
  );
};
