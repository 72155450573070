import { useEffect, useState } from 'react';

/**
 *
 * @description Кастомный хук для искуственной задержки загрузки
 */
export const useDelay = (time?: number) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsLoading(false), time || 1000);
  }, [time]);

  return isLoading;
};
