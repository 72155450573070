import { BCity } from ':types';

export const CitiesMock: BCity[] = [
  {
    id: '78',
    name: 'Самара',
    display_region: '',
    vacancies_number: 3,
  },
  {
    id: '1',
    name: 'Москва',
    display_region: '',
    vacancies_number: 2,
  },
  {
    id: '26',
    name: 'Воронеж',
    display_region: '',
    vacancies_number: 1,
  },
  {
    id: '1037',
    name: 'Светлый',
    display_region: 'Калининградская область',
    vacancies_number: 1,
  },
];
