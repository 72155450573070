import { BDirection } from ':types';

export const DirectionsMock: BDirection[] = [
  {
    direction_id: 'specialization_06',
    direction_name: 'ИТ-решения',
    direction_count: 6,
    roles: [
      {
        role_id: 'role_052',
        role_name: 'Devops',
        role_count: 4,
      },
      {
        role_id: 'role_048',
        role_name: 'Backend',
        role_count: 2,
      },
      {
        role_id: 'role_149',
        role_name: 'BI аналитика',
        role_count: 6,
      },
      {
        role_id: 'role_051',
        role_name: 'Тестирование',
        role_count: 4,
      },
    ],
  },
  {
    direction_id: 'specialization_05',
    direction_name: 'Инженерия объектов связи',
    direction_count: 1,
    roles: [
      {
        role_id: 'role_032',
        role_name: 'Эксплуатация сети',
        role_count: 4,
      },
      {
        role_id: 'role_037',
        role_name: 'Аналитика',
        role_count: 3,
      },
      {
        role_id: 'role_035',
        role_name: 'Аренда позиций',
        role_count: 12,
      },
    ],
  },
];
