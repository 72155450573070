import { delay, http, HttpResponse } from 'msw';

import { CitiesMock } from './cities';
import { VacanciesPageMock } from './vacancies';
import { DirectionsMock } from './directions';
import { ProfVacancyMock } from './vacancy';

import { BCity, BDirection, BVacancy, BVacancyPage } from ':types';
import { Endpoints } from ':constants';

/**
 * @description Обработчики запросов для msw
 */
export const handlers = [
  http.get(Endpoints.vacancies(), async () => {
    await delay();
    return HttpResponse.json<BVacancyPage>(VacanciesPageMock, { status: 200 });
  }),

  http.get(Endpoints.vacancy(':id'), async () => {
    await delay();
    return HttpResponse.json<BVacancy>(ProfVacancyMock, { status: 200 });
  }),

  http.get(Endpoints.cities(), async () => {
    await delay();
    return HttpResponse.json<BCity[]>(CitiesMock, { status: 200 });
  }),

  http.get(Endpoints.directions(), async () => {
    await delay();
    return HttpResponse.json<BDirection[]>(DirectionsMock, { status: 200 });
  }),

  http.post(Endpoints.massResume(), async () => {
    await delay();
    return HttpResponse.json({}, { status: 200 });
  }),

  http.post(Endpoints.profResume(), async () => {
    await delay();
    return HttpResponse.json({}, { status: 200 });
  }),
];
