import { createContext, FC, memo, PropsWithChildren, useLayoutEffect, useMemo, useState } from 'react';

import { VARIABLES } from './constants';

export interface ViewValue {
  /** largeDown: < 1400px */
  isLargeViewDown: boolean;
  /** largeUp: >= 1400px */
  isLargeViewUp: boolean;

  /** mobilePlusDown: < 768px */
  isMobilePlusViewDown: boolean;
  /** mobilePlusUp: >= 768px */
  isMobilePlusViewUp: boolean;
  /** mobileDown: < 600px */
  isMobileViewDown: boolean;
  /** mobileUp: >= 600px */
  isMobileViewUp: boolean;

  /** normalDown: < 1024px */
  isNormalViewDown: boolean;
  /** normalUp: >= 1024px */
  isNormalViewUp: boolean;
  /** tinyDown: < 360px */
  isTinyViewDown: boolean;
  /** tinyUp: >= 360px */
  isTinyViewUp: boolean;

  /** Определен как Boolean(navigator.maxTouchPoints) */
  isTouchDevice?: boolean;
}

/**
 * @description Создает новые значение статусов экранов
 */
const generateInitialNewValues = (): ViewValue => {
  const { innerWidth } = window;

  const screen = VARIABLES;

  return {
    isLargeViewDown: innerWidth < screen.large,
    isLargeViewUp: innerWidth >= screen.large,
    isMobilePlusViewDown: innerWidth < screen.smallPlus,
    isMobilePlusViewUp: innerWidth >= screen.smallPlus,
    isMobileViewDown: innerWidth < screen.small,
    isMobileViewUp: innerWidth >= screen.small,
    isNormalViewDown: innerWidth < screen.normal,
    isNormalViewUp: innerWidth >= screen.normal,
    isTinyViewDown: innerWidth < screen.tiny,
    isTinyViewUp: innerWidth >= screen.tiny,
  };
};

export const MobileViewContext = createContext(generateInitialNewValues());

/**
 * @description Обертка проверки на мобильный экран
 */
export const MobileViewWrapper: FC<PropsWithChildren> = memo(({ children }) => {
  const [viewValue, setViewValue] = useState(generateInitialNewValues());
  /**
   * @description Определение touch устройства
   * Вызывается рирендер при использовании эмулятора мобильных устройств в Chrome
   */
  const isTouchDevice = Boolean(navigator.maxTouchPoints);
  const contextValue = useMemo(() => ({ ...viewValue, isTouchDevice }), [viewValue, isTouchDevice]);

  /**
   * @description Устанавливает найденные значения верстки в зависимости от экрана
   */
  const defineScreenSize = () => {
    const newValue: ViewValue = generateInitialNewValues();

    setViewValue((oldValue) => {
      const oldValueKeys = Object.keys(oldValue) as Array<keyof ViewValue>;
      if (oldValueKeys.some((key) => oldValue[key] !== newValue[key])) {
        return newValue;
      }

      return oldValue;
    });
  };

  /**
   * @description Слежка за сменой разрешения
   */
  useLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(defineScreenSize);

    resizeObserver.observe(window.document.body);
  }, []);

  return <MobileViewContext.Provider value={contextValue}>{children}</MobileViewContext.Provider>;
});
