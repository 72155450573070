import { FC } from 'react';
import { Typography } from '@beeline/design-system-react';
import { colorTextInactive } from '@beeline/design-tokens/js/tokens/globals/colors';

import { ListItem } from ':components/ListItem';

import styles from './styles.module.scss';
import { useCardVariant } from './hooks';

export interface CardProps {
  /** Цвет иконки */
  color: string;
  /** Иконка */
  icon: string;
  /** Список subtitle */
  subtitleList: string[];
  /** Title */
  title: string;
}

/**
 * @description Карточка рабочих плюшек
 */
export const WorkCard: FC<CardProps> = ({ icon, title, color, subtitleList }) => {
  const { subtitleVariant, titleVariant } = useCardVariant();

  return (
    <div className={styles.card}>
      <div className={styles.icon} style={{ backgroundColor: color }}>
        <img alt={title} className={styles.iconImage} src={icon} />
      </div>
      <Typography className={styles.title} variant={titleVariant}>
        {title}
      </Typography>
      <div className={styles.subtitle}>
        {subtitleList.map((subtitle) => (
          <ListItem key={subtitle} color={colorTextInactive} text={subtitle} variant={subtitleVariant} />
        ))}
      </div>
    </div>
  );
};
