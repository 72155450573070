export interface GetSwipeAngleParams {
  /** Дельта по горизонтали */
  deltaX: number;
  /** Дельта по вертикали */
  deltaY: number;
}

/**
 * @description Получение угла для свайпа
 */
export const getSwipeAngle = ({ deltaX, deltaY }: GetSwipeAngleParams) => {
  const radians = Math.atan2(deltaY, deltaX);
  const swipeAngle = Math.round((radians * 180) / Math.PI);

  if (swipeAngle < 0) {
    return 360 - Math.abs(swipeAngle);
  }

  return swipeAngle;
};
