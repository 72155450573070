import { fetchFn } from './fetchFn';

/**
 * @description Интерфейс обертки над fetch
 */
export const API = {
  get: fetchFn('get'),
  post: fetchFn('post'),
  put: fetchFn('put'),
  delete: fetchFn('delete'),
};
