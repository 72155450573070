import { FC } from 'react';

import { Direction } from './Direction';
import styles from './styles.module.scss';

import { BDirection } from ':types';

interface Props {
  /** Массив направлений */
  directions: BDirection[];
  /** Коллбек изменения */
  onChange: (selectedIds: string[]) => void;
  /** Массив идентификаторов выбранных ролей */
  values: string[];
}

/**
 * @description Компонент дерева чекбоксов
 */
export const CheckboxTree: FC<Props> = ({ directions, onChange: handleChange, values }) => {
  /**
   * TODO: На данный момент state в DSR/<Tree /> невозможно контроллировать из-за багов.
   * onChange перезатирает значения при регистрации DSR/<TreeNode />
   */
  return (
    <div className={styles.checkboxTree}>
      {directions.map(({ direction_name: directionName, direction_id: directionId, roles }) => {
        return (
          <Direction
            key={directionId}
            directionName={directionName}
            onChange={handleChange}
            roles={roles}
            values={values}
          />
        );
      })}
    </div>
  );
};
