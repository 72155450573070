import { FC } from 'react';
import { Icon, Typography, TypographyVariant } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont/icons';
import { colorTextActive } from '@beeline/design-tokens/js/tokens/globals/colors';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  color?: string;
  text: string;
  variant?: TypographyVariant;
}

export const ListItem: FC<Props> = ({ className, color = colorTextActive, text, variant = 'body2' }) => {
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.icon}>
        <Icon className={styles.dotIcon} iconName={Icons.Dot} size="small" style={{ color }} />
      </div>
      <Typography style={{ color }} variant={variant}>
        {text}
      </Typography>
    </div>
  );
};
