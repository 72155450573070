import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { ImageMessage, ImageMessageProps } from '..';

import styles from './styles.module.scss';

import error500 from ':assets/svg/500.svg';
import error403 from ':assets/svg/403.svg';
import buoy from ':assets/svg/buoy.svg';

interface Props {
  /** Имя класса */
  className?: string;
  /** Обработчик нажатия на главную кнопку */
  onPrimaryClick?: () => void;
  /** Обработчик нажатия на вспомогательную кнопку */
  onSecondaryClick?: () => void;
  /** Тип ошибки */
  variant: '404' | '500' | 'archived';
}

const email = 'mail@beeline.ru';

/**
 * @description Компонент отображения ошибки сервиса
 */
export const ServiceError: FC<Props> = ({ className, variant, onPrimaryClick, onSecondaryClick }) => {
  const navigate = useNavigate();
  const refreshPage = () => navigate(0);

  const variantMap: Record<Props['variant'], ImageMessageProps> = {
    404: {
      alt: 'Ошибка',
      description: 'Запросите доступ к странице у владельца продукта',
      src: error403,
      title: 'Нет доступа',
      primaryButton: {
        text: 'Обновить страницу',
        onClick: onPrimaryClick ?? refreshPage,
      },
      secondaryButton: {
        text: 'Назад',
        onClick: onSecondaryClick,
      },
    },

    500: {
      alt: 'Ошибка',
      description: (
        <div className={styles.description}>
          Попробуйте обновить страницу. Если проблема повторяется, напишите в поддержку{' '}
          <a className={styles.link} href={`mailto:${email}`}>
            {email}
          </a>
        </div>
      ),
      src: error500,
      title: 'Не удалось загрузить данные',
      primaryButton: {
        text: 'Обновить страницу',
        onClick: onPrimaryClick ?? refreshPage,
      },
      secondaryButton: {
        text: 'Назад',
        onClick: onSecondaryClick,
      },
    },

    archived: {
      alt: 'Ошибка',
      description: 'Другие интересные вакансии можно посмотреть, перейдя на страницу вакансий',
      src: buoy,
      title: 'Эта вакансия уже закрыта',
      primaryButton: {
        text: 'Вернуться к вакансиям',
        onClick: onPrimaryClick,
      },
    },
  };

  return <ImageMessage className={className} {...variantMap[variant]} />;
};
