import { Routes } from './routes';

const {
  VITE_LOCAL_MOCK,
  VITE_BUILD_DATE,
  VITE_BUILD_ENV_NAME,
  VITE_COMMIT_HASH,
  VITE_LK_API_SERVER_URL,
  VITE_LK_ENV,
  VITE_VERSION,
  VITEST,
} = import.meta.env;

export * from './routes';
export * from './endpoints';

export const APP_BUILD_DATE = VITE_BUILD_DATE;
export const APP_COMMIT_HASH = VITE_COMMIT_HASH;
export const APP_VERSION = VITE_VERSION;
export const APP_BUILD_ENV_NAME = VITE_BUILD_ENV_NAME;
export const IS_DEV = VITE_LK_ENV === 'development';
export const IS_LOCAL_MOCK = Boolean(VITE_LOCAL_MOCK);
export const IS_TEST_ENV = Boolean(VITEST);
export const APP_API_URL = VITE_LK_API_SERVER_URL;

/**
 * @description Информация о табах
 */
export const TabsInfo = {
  Home: {
    label: 'Главная',
    routes: [Routes.Home],
  },
  Vacancies: {
    label: 'Вакансии',
    routes: [Routes.Vacancies, Routes.Vacancy],
  },
};

/**
 * @description Воронки подбора
 * @see https://bwiki.beeline.ru/pages/viewpage.action?pageId=545460659
 */
export const JobTypes = {
  Prof: [
    /** Проф. подбор */
    '1',
    /** ЦПК (точечные) */
    '3',
  ],
  Intern: [
    /** Стажировка */
    '2',
  ],
  Mass: [
    /** ЦПК (массовые) */
    '4',
    /** Монобренд */
    '5',
    /** ШПД */
    '6',
  ],
};
