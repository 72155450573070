import { FC, PropsWithChildren } from 'react';
import cn from 'clsx';

import styles from './styles.module.scss';

interface Props {
  /** Состояние открытия */
  open: boolean;
}

/**
 * @description Компонент анимированного скрытия данных
 */
export const Collapse: FC<PropsWithChildren<Props>> = ({ open, children }) => {
  return (
    <div className={cn(styles.collapse, open && styles.open)}>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
