import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants';
import { BWorkFormat } from ':types';
import { API } from ':api';

/**
 * @description Загрузка фильтров формата работы
 */
export const useWorkFormats = () => {
  return useQuery<BWorkFormat[]>({
    queryKey: ['workFormat'],
    queryFn: () => {
      return API.get(Endpoints.workFormat());
    },
    initialData: [],
  });
};
