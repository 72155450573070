import { APP_API_URL } from '.';

/**
 * @description Эндпоинты JobBeeline
 */
export const Endpoints = {
  vacancies: () => `${APP_API_URL}/api/v1/vacancies/`,
  vacancy: (id: string) => `${APP_API_URL}/api/v1/vacancies/${id}`,
  workFormat: () => `${APP_API_URL}/api/v1/work-format/`,
  workType: () => `${APP_API_URL}/api/v1/work-type/`,
  grade: () => `${APP_API_URL}/api/v1/grade/`,
  cities: () => `${APP_API_URL}/api/v1/cities/`,
  directions: () => `${APP_API_URL}/api/v1/roles/`,
  profResume: () => `${APP_API_URL}/api/v1/professional-resume/`,
  massResume: () => `${APP_API_URL}/api/v1/mass-resume/`,
};
