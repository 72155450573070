import { FC, useEffect, useState } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { IconButton, Tab, Tabs, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import cn from 'clsx';

import styles from './styles.module.scss';

import { useMobileView } from ':hooks';
import { Modal } from ':components';
import MainLogo from ':assets/svg/main-logo.svg';
import Logo from ':assets/img/logo.png';
import { TabsInfo } from ':constants';
import { useSelectedTabIndex } from ':hooks';

interface Props {
  /** Имя класса */
  className?: string;
}

/**
 * @description Компонент Шапка на главной
 */
export const Header: FC<Props> = ({ className }) => {
  const { isMobilePlusViewDown } = useMobileView();
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpened, setMenuOpened] = useState(false);
  const tabIndex = useSelectedTabIndex();

  useEffect(() => {
    setMenuOpened(false);
  }, [location.pathname]);

  useEffect(() => {
    if (!isMobilePlusViewDown) {
      setMenuOpened(false);
    }
  }, [isMobilePlusViewDown]);

  const handleMenuClick = () => {
    setMenuOpened(!menuOpened);
  };

  const handleTabClick = (route: string) => () => {
    setMenuOpened(false);
    navigate(generatePath(route));
  };

  const handleMainClick = () => {
    setMenuOpened(false);
    navigate('/');
  };
  const handleVacanciesClick = () => {
    setMenuOpened(false);
    navigate('/vacancies');
  };

  if (isMobilePlusViewDown) {
    return (
      <div className={cn(styles.header, className)}>
        <img alt="" className={styles.logo} src={Logo} />
        <IconButton iconName={Icons.Menu} onClick={handleMenuClick} size="large" />
        {menuOpened && (
          <Modal className={styles.modalMenu} open={menuOpened}>
            <div className={styles.mobileMenu}>
              <Typography className={styles.text} onClick={handleMainClick} variant="body2">
                Главная
              </Typography>
              <Typography className={styles.text} onClick={handleVacanciesClick} variant="body2">
                Вакансии
              </Typography>
              {/* <Typography className={styles.text} onClick={handleInternClick} variant="body2">
                Стажировки
              </Typography> */}
            </div>
          </Modal>
        )}
      </div>
    );
  }

  return (
    <div className={cn(styles.header, className)}>
      <a href="/">
        <Typography className={styles.productName} variant="h4">
          работа
        </Typography>
        <img alt="" src={MainLogo} />
      </a>

      <Tabs className={styles.tabs} selectedTabIndex={tabIndex}>
        {Object.values(TabsInfo).map(({ label, routes }) => {
          const [route] = routes;
          return <Tab key={label} label={label} onClick={handleTabClick(route)} />;
        })}
      </Tabs>
    </div>
  );
};
