import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Button, IconButton, Progress, Typography } from '@beeline/design-system-react';
import { Icons } from '@beeline/design-tokens/js/iconfont';
import cn from 'clsx';

import styles from './styles.module.scss';

import { PinCode, RefProps } from ':components';
import MainLogo from ':assets/svg/main-logo.svg';

const retryTimeout = 59;

interface Props {
  /** Номер телефона */
  number: string;
  /** Сменить номер */
  onChangeNumber: () => void;
  /** Закрытие модалки */
  onClose: () => void;
  /** Повторный запрос кода */
  onRetry: () => void;
  /** Успешный вход */
  onSuccess: () => void;
}

/**
 * @description Компонент MobileId
 */
export const MobileId: FC<Props> = ({ number, onChangeNumber, onClose: handleClose, onRetry, onSuccess }) => {
  const [fetching, setFetching] = useState(false);
  const [error, setError] = useState(false);
  const [errorRetry, setErrorRetry] = useState(0);

  const [timeLeft, setTimeLeft] = useState(0);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);
  const codeRef = useRef<RefProps>(null);

  const errorCode = (retryCount: number) => {
    setError(true);
    setErrorRetry(retryCount);
    codeRef.current?.clear();
  };

  const onPinEnter = (code: string) => {
    console.log(code);
    setError(false);
    setFetching(true);

    // Заменить на fetch(code). правильный код - onSuccess, ошибка errorCode(n) (n - количество попыток, в ответе fetch)
    setTimeout(() => {
      setFetching(false);
      if (Math.random() < 0.5) {
        onSuccess();
        return;
      }

      errorCode(2);
    }, 3000);
  };

  const stopTimer = () => {
    if (timer.current) {
      clearInterval(timer.current);
      setTimeLeft(0);
    }
  };

  const startTimer = useCallback(() => {
    setTimeLeft(retryTimeout);
    timer.current = setInterval(() => {
      setTimeLeft((val) => {
        const value = val - 1;
        if (value === 0) {
          stopTimer();
        }

        return value;
      });
    }, 1000);
  }, []);

  useEffect(() => {
    startTimer();

    return () => {
      stopTimer();
    };
  }, [startTimer]);

  const handleRetry = () => {
    codeRef.current?.clear();
    setError(false);
    onRetry();
    startTimer();
  };

  const handleChangeNumber = () => {
    stopTimer();
    onChangeNumber();
  };

  const comment = () => {
    if (error) {
      if (errorRetry > 0) {
        return `Неверный код. Осталось ${errorRetry} ${errorRetry === 1 ? 'попытка' : 'попытки'}.`;
      }
      return 'Слишком много попыток — попробуйте снова через 1 мин';
    }

    if (timeLeft > 0) {
      return `До повторной отправки кода ${timeLeft} сек`;
    }

    return 'Время ожидания истекло — запросите код повторно';
  };

  return (
    <div className={styles.mobileid}>
      <div className={styles.card}>
        <div className={styles.header}>
          <IconButton disabled={fetching} iconName={Icons.ArrowLeft} onClick={handleClose} size="large" />
          <div className={cn(styles.mainIcon, fetching && styles.disabled)}>
            <Typography className={styles.work}>работа</Typography>
            <img alt="" height="25" src={MainLogo} />
          </div>
          <div className={styles.space} />
        </div>
        <Typography className={fetching && styles.disabled} variant="h5">
          мы отправили код на номер
          <br />
          {number}
        </Typography>
        <div className={styles.pinCode}>
          <PinCode ref={codeRef} disabled={fetching} error={error} pinComplete={onPinEnter} />
          <Typography className={error && styles.error} variant="caption">
            {comment()}
          </Typography>
        </div>
        <div className={styles.bottom}>
          <Button disabled={fetching} onClick={handleChangeNumber} size="large" variant="plain">
            Изменить номер
          </Button>
          <Button disabled={Boolean(timeLeft) || fetching} onClick={handleRetry} size="large" variant="plain">
            Отправить код повторно
          </Button>
        </div>
        {fetching && <Progress className={styles.progress} cycled />}
      </div>
    </div>
  );
};
