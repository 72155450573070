import { useState, ChangeEvent } from 'react';
import { Button, Search, Typography } from '@beeline/design-system-react';
import { useNavigate } from 'react-router-dom';

import styles from './styles.module.scss';
import { useVariant } from './hooks';

const linkTags = [
  'Мерчендайзер',
  'Backend',
  'Frontend',
  'Инженер связи',
  'Монтажник',
  'Продавец-консультант',
  'Оператор колл-центра',
  'КАМ',
];

/**
 * @description Блок с поиском вакансий
 */
export const SearchBlock = () => {
  const [searchValue, setSearchValue] = useState('');
  const { buttonVariant, subtitleVariant, findButton, tagVariant } = useVariant();
  const navigate = useNavigate();

  const handleTagClick = () => {
    navigate('/vacancies');
  };

  const handleClear = () => {
    setSearchValue('');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <div className={styles.searchBlock}>
      <Typography className={styles.title} variant="h2">
        каждый человек важен
      </Typography>
      <Typography className={styles.subtitle} variant={subtitleVariant}>
        билайн — компания, для которой каждый&nbsp;человек&nbsp;важен.
        <br />
        Мы стремимся оказывать сервис самого высокого уровня, создавая цифровые и&nbsp;телеком-продукты не только для
        наших клиентов, но&nbsp;и&nbsp;сотрудников
      </Typography>
      <div className={styles.searchRow}>
        <Search
          fullWidth
          inputClassName={styles.search}
          onChange={handleChange}
          onClear={handleClear}
          placeholder="Введите название вакансии"
          value={searchValue}
        />
        {findButton && (
          <Button size={buttonVariant} variant="contained">
            Найти
          </Button>
        )}
      </div>
      <div className={styles.tagRow}>
        {linkTags.map((tag) => (
          <Typography key={tag} className={styles.linkTag} onClick={handleTagClick} variant={tagVariant}>
            #{tag}
          </Typography>
        ))}
      </div>
    </div>
  );
};
