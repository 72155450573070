import { useQuery } from '@tanstack/react-query';

import { Endpoints } from ':constants/endpoints';

import { BVacancyPage } from ':types';
import { API } from ':api';

export const useVacancies = () => {
  return useQuery<BVacancyPage>({
    queryKey: ['vacancies'],
    queryFn: () => {
      return API.get(Endpoints.vacancies());
    },
    initialData: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
  });
};
