import { Button, Icon, Typography, TypographyProps } from '@beeline/design-system-react';
import { FC } from 'react';
import { Icons } from '@beeline/design-tokens/js/iconfont';

import { useMobileView } from ':hooks/index';

import styles from './styles.module.scss';

interface Props {
  /** Обработчик нажатия на кнопку назад */
  onClick: () => void;
  /** Заголовок */
  title: string;
}

/**
 * @description Компонент отображения заголовка
 */
export const VacancyFormHeader: FC<Props> = ({ title, onClick: handleClick }) => {
  const { isMobileViewUp, isNormalViewUp } = useMobileView();

  /**
   * TODO: сделать обертку над <Typography variant={{ xs: 'h4', sm: 'h6' }} />
   * или просто миксины для css
   */
  const getVariant = (): TypographyProps['variant'] => {
    if (isNormalViewUp) {
      return 'h1';
    }

    if (isMobileViewUp) {
      return 'h2';
    }

    return 'h4';
  };

  return (
    <div className={styles.vacancyFormHeader}>
      <Button onClick={handleClick} startIcon={<Icon iconName={Icons.ArrowLeft} size="small" />} variant="plain">
        Вернуться к вакансии
      </Button>

      <Typography className={styles.text} variant={getVariant()}>
        {title}
      </Typography>
    </div>
  );
};
